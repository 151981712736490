import { graphql } from 'gatsby'
import React from 'react'
import { Box, Heading, Text } from 'rebass'
import Layout from '../components/Layout'
import Container from '../components/Container'
import PageHeader from '../components/PageHeader'
import { allowedLanguages } from '../i18n-config'

const [defaultLanguage] = allowedLanguages

const NotFoundPage = ({ data }) => {
  const translations = data.translations.childTranslationsJson
  const content = data.content.childContentJson

  return (
    <Layout
      seo={{
        title: translations.meta.title,
        description: translations.meta.description,
        lang: defaultLanguage,
        shareImage: data.shareImage.childImageSharp.fixed,
      }}
      translations={translations}
      apartments={content.apartments}
    >
      <PageHeader variant="small" />
      <Container
        as="section"
        minHeight={600}
        maxWidth={700}
      >
        <Box
          p={4}
          textAlign="center"
        >
          <Heading
            as="h1"
          >
            {translations.notFound.title}
            <Text fontSize={2} mt={2}>
              {translations.notFound.description}
            </Text>
          </Heading>
        </Box>
      </Container>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query NotFoundPageQuery {
    content: file(
      name: { eq: "en" },
      absolutePath: {regex: "/content/"}
    ) {
      childContentJson {
        address
        phoneNumber
        emailAddress
        apartments {
          name
          path
          pageTitle
        }
      }
    }

    translations: file(
      name: { eq: "en" }
      absolutePath: {regex: "/translations/"}
    ) {
      childTranslationsJson {
        ...CommonTranslationFields
        notFound {
          title
          description
        }
      }
    }

    shareImage: file(
      name: { eq: "share" }
    ) {
      childImageSharp {
        fixed(width: 1200) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
  }
`

